import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import PortableText from '../components/richText'
import useSite from '../hooks/use-site'
import styled from 'styled-components'

export const query = graphql`
  query PrivacyPageQuery {
    privacy: sanityPrivacy {
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const PoliciesStyles = styled.div`
  margin: 0 auto;
  h1 {
    font-size: 2rem;
  }
  p {
    margin-bottom: 1rem;
  }
`;

const PrivacyPage = ({ data }) => {
  const site = useSite()
  console.log(data)

  return (
    <Layout>
      <SEO title={site.title} description={site.description} />
      <PoliciesStyles className="max-w-screen-lg serif p-8">
        <PortableText blocks={data.privacy._rawContent} />
      </PoliciesStyles>
    </Layout>
  )
}

export default PrivacyPage
